<template>
	<!-- 新增文章专栏 -->
	<div class="container">
		<div class="policyCompilationTitle">电子政策汇编系统</div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
			<el-breadcrumb-item :to="{ path: '/' }">
				<el-button type="primary" size="mini">返回</el-button>
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				<el-button type="text" size="mini" style="color: #555;font-size: 14px;">成员详情</el-button>
			</el-breadcrumb-item>
		</el-breadcrumb>

		<el-form label-width="80px" :model="ruleForm">
			<el-form-item label="姓名:">
				<span>{{ruleForm.name}}</span>
			</el-form-item>
			
			<el-form-item label="手机号码:">
				<span>{{ruleForm.mobile}}</span>
			</el-form-item>

			<el-form-item label="邮箱:">
				<span>{{ruleForm.email}}</span>
			</el-form-item>

			<el-form-item label="品牌:">
				<span>{{ruleForm.brand}}</span>
			</el-form-item>

			<el-form-item label="公司名称:">
				<span>{{ruleForm.company}}</span>
			</el-form-item>

			<el-form-item label="身份角色	:">
				<span v-for="item in value" :key="item.name">{{item.title}}</span>
			</el-form-item>
		</el-form>
	</div>

</template>
<script>
	export default {
		data() {
			return {
				ruleForm: {
					name: '',
					mobile: '',
					email: '',
					brand: '',
					company: '',
					id: '',
					roles:[]
				},
				id: '',
				value: ''
			}
		},
		mounted() {
			this.id = sessionStorage.organizationId;
			if(this.$route.query.id){
				this.$http.get(this.URL.adminUrl.managersList + this.id + '/managers/' + this.$route.query.id).then(res => {
					const result = res.data.data;
					this.ruleForm.name = result.name;
					this.ruleForm.mobile = result.mobile;
					this.ruleForm.email = result.email;
					this.ruleForm.brand = result.brand;
					this.ruleForm.company = result.company;
					this.value = result.role_names;
				})
			}
		},
	}
</script>


<style scoped>
	.policyCompilationTitle {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		font-size: 18px;
		height: 40px;
	}

	.navbar {
		margin-bottom: 30px;
		padding: 15px;
		border-bottom: 1px solid #ccc
	}
</style>

